import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export const constantRoutes = [
  // 闸口缴费
  // {
  //   path: "/parkingFee",
  //   component: () => import("@/views/parkingFee"),
  //   meta: { title: "丽日购", icon: "dashboard" }
  // },
  // h5
  {
    path: "/h5.html",
    component: () => import("@/views/h5"),
    meta: { title: "丽日购", icon: "dashboard" }
  },
  // 问卷
  {
    path: "/surveyKing",
    component: () => import("@/views/appraisal"),
    meta: { title: "丽日评价", icon: "dashboard" }
  },
  // 登陆
  {
    path: "/login",
    component: () => import("@/views/login/index")
  },
  {
    name: "print",
    path: "/print",
    component: () => import("@/views/print/index")
  },
  // 内容
  {
    path: "/",
    component: () => import("@/views/index"),
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("@/views/dashboard/index"),
        meta: { title: "首页看板", icon: "dashboard" }
      },
      {
        path: "/transactions/finance_manage/finance_details",
        component: () => import("@/views/transactions/finance_manage/finance_details/index"),
        name: "Finance_details",
        meta: { title: "交易中心 / 财务管理" }
      },
      {
        path: "/transactions/orders/orders_department_list",
        component: () => import("@/views/transactions/orders/orders_department_list/index"),
        name: "Orders_department_list",
        meta: { title: "交易中心 / 订单管理 / 订单列表" }
      },
      {
        path: "/transactions/orders/orders_dada_cancel_request",
        component: ()=> import("@/views/transactions/orders/orders_dada_cancel_request/index"),
        name: "Orders_dada_cancel_request",
        meta: {
          title: "交易中心 / 订单管理 / 达达订单取消申请"
        }
      },
      {
        path: "/transactions/orders/orders_department_details",
        component: () => import("@/views/transactions/orders/orders_department_details/index"),
        name: "Orders_department_details",
        meta: {
          title: "交易中心 / 订单管理 / 订单详情",
          activeMenu: "/transactions/orders/orders_department_details"
        }
      },
      {
        path: "/transactions/orders/orders_department_refund",
        component: () => import("@/views/transactions/orders/orders_department_refund/index"),
        name: "Orders_department_refund",
        meta: { title: "交易中心 / 订单管理 / 百货退款审核" }
      },
      {
        path: "/transactions/orders/orders_home_list",
        component: () => import("@/views/transactions/orders/orders_home_list/index"),
        name: "Orders_home_list",
        meta: { title: "交易中心 / 订单管理 / 到家订单列表" }
      },
      {
        path: "/transactions/orders/orders_home_details",
        component: () => import("@/views/transactions/orders/orders_home_details/index"),
        name: "Orders_home_details",
        meta: {
          title: "交易中心 / 订单管理 / 订单详情",
          activeMenu: "/transactions/orders/orders_home_list"
        }
      },
      {
        path: "/transactions/orders/orders_config",
        component: () => import("@/views/transactions/orders/orders_config/index"),
        name: "Orders_config",
        meta: { title: "交易中心 / 订单管理 / 到家订单配置" }
      },
      {
        path: "/transactions/orders/orders_refund",
        component: () =>
          import("@/views/transactions/orders/orders_refund/index"),
        name: "Orders_refund",
        meta: { title: "交易中心 / 订单管理 / 到家退款审核" }
      },
      {
        path: "/transactions/orders/orders_repast_list",
        component: () =>
          import("@/views/transactions/orders/orders_repast_list/index"),
        name: "Orders_repast_list",
        meta: { title: "交易中心 / 订单管理 / 餐饮订单列表" }
      },
      {
        path: "/transactions/orders/orders_repast_details",
        component: () =>
          import("@/views/transactions/orders/orders_repast_details/index"),
        name: "Orders_repast_details",
        meta: {
          title: "交易中心 / 订单管理 / 订单详情",
          activeMenu: "/transactions/orders/orders_repast_details"
        }
      },
      {
        path: "/transactions/orders/orders_repast_refund",
        component: () =>
          import("@/views/transactions/orders/orders_repast_refund/index"),
        name: "Orders_repast_refund",
        meta: { title: "交易中心 / 订单管理 / 餐饮退款审核" }
      },
      {
        name: "Stores_list",
        path: "/service_center/stores/stores_list",
        meta: { title: "门店管理" },
        component: () =>
          import("@/views/service_center/stores/stores_list/index")
      },
      {
        name: "goods_classify",
        path: "/config_center/goods_classify",
        meta: { title: "服务中心 / 商品分类管理" },
        component: () =>
            import("@/views/config_center/goods_classify/index")
      },
      {

        name: "Stores_new",
        path: "/service_center/stores/stores_new",
        meta: {
          title: "新建门店",
          activeMenu: "/service_center/stores/stores_list"
        },
        component: () =>
          import("@/views/service_center/stores/stores_new/index")
      },
      {
        name: "Merchants_list",
        path: "/service_center/merchants/merchants_list",
        meta: { title: "服务中心 / 商户管理 / 商户列表" },
        component: () =>
          import("@/views/service_center/merchants/merchants_list/index")
      },
      {
        name: "Merchants_type",
        path: "/service_center/merchants/merchants_type",
        meta: { title: "服务中心 / 商户管理 / 商户分类" },
        component: () =>
          import("@/views/service_center/merchants/merchants_type/index")
      },
      {
        name: "Merchants_new",
        path: "/service_center/merchants/merchants_new",
        meta: { title: "服务中心 / 添加商户" },

        component: () =>
          import("@/views/service_center/merchants/merchants_new/index")
      },
      {
        name: "Parking_fee",
        path: "/service_center/parking/parking_fee",
        meta: { title: "服务中心 / 停车管理 / 停车缴费" },
        component: () =>
          import("@/views/service_center/parking/parking_fee/index")
      },
      {
        name: "Parking_intro",
        path: "/service_center/parking/parking_intro",
        meta: { title: "服务中心 / 停车管理 / 停车场介绍" },
        component: () =>
          import("@/views/service_center/parking/parking_intro/index")
      },
      {
        name: "Parking_list",
        path: "/service_center/parking/parking_list",
        meta: { title: "服务中心 / 停车管理 / 停车场列表" },
        component: () =>
            import("@/views/service_center/parking/parking_list/index")
      },
      {
        name: "Verify_merchants_list",
        path: "/verify_center/verify_merchants_list",
        meta: { title: "核销中心 / 核销商户" },
        component: () =>
          import("@/views/verify_center/verify_merchants/verify_merchants_list/index")
      },
      {

        name: "Verify_merchants_new",
        path: "/verify_center/verify_merchants_new",
        meta: {
          title: "核销中心 / 添加商户"
        },
        component: () =>
          import("@/views/verify_center/verify_merchants/verify_merchants_new/index")
      },
      {
        name: "Verify_records_activity",
        path: "/verify_center/verify_records/verify_records_activity",
        meta: { title: "会员活动核销" },
        component: () =>
          import("@/views/verify_center/verify_records/verify_records_activity/index")
      },
      {
        name: "Verify_records_orders",
        path: "/verify_center/verify_records/verify_records_orders",
        meta: { title: "订单核销" },
        component: () =>
          import("@/views/verify_center/verify_records/verify_records_orders/index")
      },
      {
        name: "Verify_records_awards",
        path: "/verify_center/verify_records/verify_records_awards",
        meta: { title: "奖品核销" },
        component: () =>
          import("@/views/verify_center/verify_records/verify_records_awards/index")
      },
      {
        name: "Verify_records_coupons",
        path: "/verify_center/verify_records/verify_records_coupons",
        meta: { title: "优惠券核销" },
        component: () =>
          import("@/views/verify_center/verify_records/verify_records_coupons/index")
      },
      {
        name: "Verify_records_department",
        path: "/verify_center/verify_records/verify_records_department",
        meta: { title: "百货订单核销" },
        component: () =>
          import("@/views/verify_center/verify_records/verify_records_department/index")
      },
      {
        name: "Verify_records_repast",
        path: "/verify_center/verify_records/verify_records_repast",
        meta: { title: "餐饮团购核销" },
        component: () =>
          import("@/views/verify_center/verify_records/verify_records_repast/index")
      },
      {
        name: "Verify_records_repast_coupon",
        path: "/verify_center/verify_records/verify_records_repast_coupon",
        meta: { title: "餐饮优惠券核销" },
        component: () =>
          import("@/views/verify_center/verify_records/verify_records_repast_coupon/index")
      }, {
        name: "Orders_setting",
        path: "/config_center/orders/orders_setting",
        meta: { title: "配置中心 / 订单关闭设置" },
        component: () =>
          import("@/views/config_center/orders_setting/index")
      },
      {
        path: "/config_center/shipping_config",
        name: "Shipping_config",
        meta: { title: "配置中心 / 运费设置" },
        component: () => import("@/views/config_center/shipping_config/index"),
      },
          {
        path: "/config_center/WeChatTemplateMessage_config",
        name: "WeChatTemplateMessage_config",
        meta: { title: "微信模板消息设置" },
        component: () => import("@/views/config_center/WeChatTemplateMessage_config/index"),
      },
      {
        path: "/config_center/ticket_config",
        name: "Ticket_config",
        meta: { title: "配置中心 / 小票打印设置" },
        component: () => import("@/views/config_center/ticket_config"),
      },
      {
        name: "Miniprogram_about",
        path: "/config_center/miniprogram/miniprogram_about",
        meta: { title: "配置中心 / 小程序设置 / 关于我们" },
        component: () =>
          import("@/views/config_center/miniprogram/miniprogram_about")
      },
      {
        name: "Miniprogram_news",
        path: "/config_center/miniprogram/miniprogram_news",
        meta: { title: "配置中心 / 小程序设置 / 丽日快讯" },
        component: () =>
          import("@/views/config_center/miniprogram/miniprogram_news")
      },
      {
        name: "Miniprogram_popup",
        path: "/config_center/miniprogram/miniprogram_popup",
        meta: { title: "配置中心 / 小程序设置 / 首页弹窗" },
        component: () =>
          import("@/views/config_center/miniprogram/miniprogram_popup")
      },
      {
        name: "Miniprogram_ad",
        path: "/config_center/miniprogram/miniprogram_ad",
        meta: { title: "配置中心 / 小程序设置 / 首页广告" },
        component: () =>
          import("@/views/config_center/miniprogram/miniprogram_ad")
      },
      {
        name: "Miniprogram_hotSearch",
        path: "/config_center/miniprogram/miniprogram_hotSearch",
        meta: { title: "配置中心 / 小程序设置 / 热搜词" },
        component: () =>
          import("@/views/config_center/miniprogram/miniprogram_hotSearch")
      },
      {
        name: "Miniprogram_banner",
        path: "/config_center/miniprogram/miniprogram_banner",
        meta: { title: "配置中心 / 小程序设置 / Banner图" },
        component: () =>
          import("@/views/config_center/miniprogram/miniprogram_banner")
      },
      {
        name: "Miniprogram_kingKong",
        path: "/config_center/miniprogram/miniprogram_kingKong",
        meta: { title: "配置中心 / 小程序设置 / 金刚区" },
        component: () =>
          import("@/views/config_center/miniprogram/miniprogram_kingKong")
      },
      {
        name: "Miniprogram_subscribeNews",
        path: "/config_center/miniprogram/miniprogram_subscribe/subscribeNews",
        meta: { title: "配置中心 / 小程序设置 / 新增订阅消息模板" },
        component: () =>
          import("@/views/config_center/miniprogram/miniprogram_subscribe/subscribeNews")
      },
      {
        name: "Miniprogram_subscribeList",
        path: "/config_center/miniprogram/miniprogram_subscribe/subscribeList",
        meta: { title: "配置中心/小程序设置/订阅消息列表" },
        component: () =>
          import("@/views/config_center/miniprogram/miniprogram_subscribe/subscribeList")
      },
      {
        name: "order_configNew",
        path: "/transactions/orders/orders_configNew",
        meta: { title: "配置中心 / 小程序设置 / 到家基础参数设置" },
        component: () =>
          import("@/views/transactions/orders/orders_configNew")
      },
      {
        name: "Merchandise",
        path: "/market/canvas/merchandise",
        meta: { title: "百货首页画布配置" },
        component: () => import("@/views/market/canvas/merchandise")
      },
      {
        name: "Miniprogram_marketingAct",
        path: "/config_center/miniprogram/miniprogram_marketingAct",
        meta: { title: "首页专区" },
        component: () =>
          import("@/views/config_center/miniprogram/miniprogram_marketingAct")
      },
      {
        name: "Protocol_members",
        path: "/config_center/protocol/protocol_members",
        meta: { title: "配置中心 / 服务协议 / 会员服务协议" },
        component: () =>
          import("@/views/config_center/protocol/protocol_members")
      },
      {
        name: "Protocol_purchase",
        path: "/config_center/protocol/protocol_purchase",
        meta: { title: "配置中心 / 服务协议 / 购买服务协议" },
        component: () =>
          import("@/views/config_center/protocol/protocol_purchase")
      },
      {
        name: "Protocol_privacy",
        path: "/config_center/protocol/protocol_privacy",
        meta: { title: "配置中心 / 服务协议 / 隐私政策" },
        component: () =>
          import("@/views/config_center/protocol/protocol_privacy")
      },
      {
        name: "Pick_up_claus",
        path: "/config_center/protocol/pick_up_claus",
        meta: {title: "配置中心 / 服务协议 / 到店自提协议"},
        component: () =>
            import("@/views/config_center/protocol/pick_up_claus")
      },
      {
        name: "Coupons_new_old",
        path: "/marketing/coupons_old/coupons_new",
        meta: {
          title: "营销中心 / 优惠券管理(旧) / 编辑优惠券",
          activeMenu: "/marketing/coupons_old/coupons_list"
        },
        component: () =>
            import("@/views/marketing/coupons_old/coupons_new")
      },
      {
        name: "Coupons_type_old",
        path: "/marketing/coupons_old/coupons_type",
        component: () =>
            import("@/views/marketing/coupons_old/coupons_type"),
        meta: { title: "营销中心 / 优惠券管理(旧) / 优惠券品类" }
      },
      {
        name: "Coupons_list_old",
        path: "/marketing/coupons_old/coupons_list",
        meta: { title: "营销中心 / 优惠券管理(旧) / 优惠券列表" },
        component: () =>
            import("@/views/marketing/coupons_old/coupons_list")
      },
      {
        name: "Coupons_list",
        path: "/marketing/coupons/coupons_list",
        meta: { title: "营销中心 / 优惠券管理 / 优惠券列表" },
        component: () =>
            import("@/views/marketing/coupons/coupons_list")
      },
      {
        name: "Coupons_new",
        path: "/marketing/coupons/coupons_new",
        meta: {
          title: "营销中心 / 优惠券管理 / 编辑优惠券",
          activeMenu: "/marketing/coupons/coupons_list"
        },
        component: () =>
          import("@/views/marketing/coupons/coupons_new")
      },
      {
        name: "Coupons_type",
        path: "/marketing/coupons/coupons_type",
        component: () =>
          import("@/views/marketing/coupons/coupons_type"),
        meta: { title: "营销中心 / 优惠券管理 / 优惠券品类" }
      },
      {
        path: "/marketing/checkin/checkin_list",
        name: "Checkin_list",
        meta: { title: "营销中心 / 签到管理 / 签到列表" },
        component: () =>
          import("@/views/marketing/checkin/checkin_list")
      },
      {
        path: "/marketing/checkin/checkin_setting",
        name: "Checkin_setting",
        meta: { title: "营销中心 / 签到管理 / 签到设置" },
        component: () =>
          import("@/views/marketing/checkin/checkin_setting/index")
      },
      {
        path: "/marketing/internal_purchase/salesman_managed",
        name: "Salesman_managed",
        meta: { title: "营销中心 / 员工内购 / 推销员配置" },
        component: () =>
            import("@/views/marketing/internal_purchase/salesman")
      },
      {
        path: "/marketing/internal_purchase/internal_orders",
        name: "Internal_orders",
        meta: { title: "营销中心 / 员工内购 / 内购订单(拼团)" },
        component: () =>
            import("@/views/marketing/internal_purchase/orders")
      },
      {
        path: "/marketing/internal_purchase/internal_orders_osm",
        name: "Internal_orders_osm",
        meta: { title: "营销中心 / 员工内购 / 内购订单(超市)" },
        component: () =>
            import("@/views/marketing/internal_purchase/orders_osm")
      },
      {
        path: "/marketing/internal_purchase/salesman_source_managed",
        name: "Salesman_source_managed",
        meta: { title: "营销中心 / 员工内购 / 来源管理" },
        component: () =>
            import("@/views/marketing/internal_purchase/salesman_source")
      },
      {
        path: "/marketing/ripoff/ripoff_list",
        name: "Ripoff_list",
        meta: { title: "营销中心 / 分享砍价" },
        component: () =>
          import("@/views/marketing/ripoff/ripoff_list/index")
      },
      {
        path: "/marketing/ripoff/ripoff_records",
        name: "Ripoff_records",

        meta: {
          title: "营销中心 / 砍价记录",
          activeMenu: "/marketing/ripoff/ripoff_list"
        },
        component: () =>
          import("@/views/marketing/ripoff/ripoff_records/index")
      },
      {
        path: "/marketing/ripoff/ripoff_new",
        name: "Ripoff_new",

        meta: {
          title: "营销中心 / 砍价活动",
          activeMenu: "/marketing/ripoff/ripoff_list"
        },
        component: () => import("@/views/marketing/ripoff/ripoff_new/index")
      },
      {
        path: "/marketing/collecting/collecting_list",
        name: "Collecting_list",
        meta: { title: "营销中心 / 分享集卡" },
        component: () =>
          import("@/views/marketing/collecting/collecting_list/index")
      },
      {
        path: "/marketing/collecting/collecting_new",
        name: "Collecting_new",

        meta: { title: "营销中心 / 新建活动" },
        component: () =>
          import("@/views/marketing/collecting/collecting_new/index")
      },
      {
        path: "/marketing/collecting/collecting_users",
        name: "Collecting_users",

        meta: { title: "营销中心 / 查看用户" },
        component: () =>
          import("@/views/marketing/collecting/collecting_users/index")
      },
      {
        path: "/marketing/credits/credits_list",
        name: "Credits_list",
        meta: { title: "营销中心 / 积分商城" },
        component: () =>
          import("@/views/marketing/credits/credits_list/index")
      },
      {
        path: "/marketing/credits/credits_convert",
        name: "Credits_convert",
        meta: { title: "营销中心 / 积分兑换" },

        component: () =>
          import("@/views/marketing/credits/credits_convert/index")
      },
      {
        path: "/marketing/credits/credits_details",
        name: "Credits_details",
        meta: { title: "营销中心 / 积分详情" },

        component: () =>
          import("@/views/marketing/credits/credits_details/index")
      },
      {
        path: "/marketing/groupbuy/groupbuy_list",
        name: "Groupbuy_list",
        meta: { title: "营销中心 / 多人拼团" },
        component: () =>
          import("@/views/marketing/groupbuy/groupbuy_list/index")
      },
      {
        path: "/marketing/groupbuy/groupbuy_new",
        name: "Groupbuy_new",
        meta: {
          title: "营销中心 / 新增拼团",
          activeMenu: "/marketing/groupbuy/groupbuy_list"
        },

        component: () =>
          import("@/views/marketing/groupbuy/groupbuy_new/index")
      },
      {
        path: "/marketing/groupbuy/groupbuy_orders",
        name: "Groupbuy_orders",
        meta: {
          title: "营销中心 / 团购订单",
          activeMenu: "/marketing/groupbuy/groupbuy_list"
        },

        component: () =>
          import("@/views/marketing/groupbuy/groupbuy_orders/index")
      },
      {
        path: "/marketing/flashsale/flashsale_list",
        name: "Flashsale_list",
        meta: {
          title: "营销中心 / 限时抢购",
          activeMenu: "/marketing/flashsale/flashsale_list"
        },
        component: () =>
          import("@/views/marketing/flashsale/flashsale_list/index")
      },
      {
        path: "/marketing/flashsale/flashsale_order_list",
        name: "Flashsale_order_list",
        meta: {
          title: "营销中心 / 订单列表",
          activeMenu: "/marketing/flashsale/flashsale_list"
        },

        component: () =>
          import("@/views/marketing/flashsale/flashsale_order_list/index")
      },
      {
        path: "/marketing/flashsale/flashsale_new",
        name: "Flashsale_new",
        meta: {
          title: "营销中心 / 新增抢购",
          activeMenu: "/marketing/flashsale/flashsale_list"
        },

        component: () =>
          import("@/views/marketing/flashsale/flashsale_new/index")
      },
      {
        path: "/market/goods_manage/goods_list",
        component: () => import("@/views/market/goods_manage/goods_list"),
        name: "Goods_list",
        meta: {
          title: "商超商品",
          activeMenu: "/market/goods_manage/goods_list"
        }
      },
      {

        path: "/market/goods_manage/goods_new",
        component: () => import("@/views/market/goods_manage/goods_new"),
        name: "Goods_new",
        meta: {
          title: "编辑商品",
          activeMenu: "/market/goods_manage/goods_list"
        }
      },
      {
        path: "/market/flesh/flesh_list",
        component: () => import("@/views/market/flesh/flesh_list"),
        name: "Flesh_list",
        meta: {
          title: "生鲜商品",
          activeMenu: "/market/flesh/flesh_list"
        }
      },
      {

        path: "/market/flesh/flesh_new",
        component: () => import("@/views/market/flesh/flesh_new"),
        name: "Flesh_new",
        meta: {
          title: "添加生鲜商品",
          activeMenu: "/market/flesh/flesh_list"
        }
      },
      {
        path: "/market/goods_classify/classify_list",
        component: () =>
          import("@/views/market/goods_classify/classify_list"),
        name: "Classify_list",
        meta: { title: "商品分类" }
      },
      {
        path: "/config_center/equalGoodsMesg/classify",
        component: () =>
          import("@/views/config_center/equalGoodsMesg/classify"),
        name: "equalClassify",
        meta: { title: "商品分类同步" }
      },
      {
        path: "/config_center/equalGoodsMesg/storeMsg",
        component: () =>
          import("@/views/config_center/equalGoodsMesg/storeMsg"),
        name: "equalStore",
        meta: { title: "商品门店同步" }
      },
      {

        path: "/market/goods_classify/classify_new",
        component: () =>
          import("@/views/market/goods_classify/classify_new"),
        name: "Classify_new",
        meta: { title: "编辑分类" }
      },
      {
        path: "/market/corner/corner_list",
        component: () => import("@/views/market/corner/corner_list"),
        name: "Corner_list",
        meta: { title: "角标管理" }
      },
      {
        name: "Special_goods_list",
        path: "/market/special_manage/goods_list",
        meta: {
          title: "活动管理",
          activeMenu: "/market/special_manage/goods_list"
        },
        component: () =>
          import("@/views/market/special_manage/special_goods/goods_list")
      },
      {

        name: "Special_goods_new",
        path: "/market/special_manage/goods_new",
        meta: {
          title: "添加活动",
          activeMenu: "/market/special_manage/goods_list"
        },
        component: () =>
          import("@/views/market/special_manage/special_goods/goods_new")
      },
      {
        name: "Section_goods_list",
        path: "/market/section_manage/section_list",
        meta: {
          title: "专区管理",
          activeMenu: "/market/section_manage/section_list"
        },
        component: () =>
            import("@/views/market/section_manage/section_goods/section_list")
      },
      {

        name: "Section_goods_new",
        path: "/market/section_manage/section_new",
        meta: {
          title: "添加专区",
          activeMenu: "/market/section_manage/section_list"
        },
        component: () =>
            import("@/views/market/section_manage/section_goods/section_new")
      },
      {
        name: "Recommend",
        path: "/market/market_config/recommend",
        meta: { title: "为你推荐" },
        component: () => import("@/views/market/market_config/recommend")
      },
      // {
      //   name: "Config_banner",
      //   path: "/market/market_config/config_banner",
      //   meta: { title: "Banner配置" },
      //   component: () =>
      //     import("@/views/market/market_config/banner_config")
      // },
      {
        name: "canvas",
        path: "/market/canvas/index",
        meta: { title: "商超首页配置" },
        component: () => import("@/views/market/canvas/canvas_list/index")
      },
      {
        name: "Index_zone",
        path: "/market/market_config/Index_zone",
        meta: { title: "专区配置" },
        component: () => import("@/views/market/market_config/indexZone")
      },
      {
        name: "Ad_config",
        path: "/market/market_config/Ad_config",
        meta: { title: "广告配置" },
        component: () => import("@/views/market/market_config/ad_config")
      },
      {
        name: "Fullcut",
        path: "/market/market_config/fullcut",
        meta: { title: "满减配置" },
        component: () => import("@/views/market/market_config/fullcut")
      },
      {
        name: "Delivery_fee_config",
        path: "/market/market_config/delivery_fee_config",
        meta: { title: "配送费配置" },
        component: () => import("@/views/market/market_config/delivery_fee_config")
      },
      // {
      //   name: "Ticket",
      //   path: "/market/market_config/ticket",
      //   meta: { title: "小票打印设置" },
      //   component: () => import("@/views/market/market_config/ticket")
      // },
      {
        path: "/members/members_manage/members_list",
        component: () =>
          import("@/views/members/members_manage/members_list/index"),
        name: "Members_list",
        meta: { title: "会员中心 / 会员管理" }
      },
      {

        path: "/members/members_manage/members_details",
        component: () =>
          import("@/views/members/members_manage/members_details/index"),
        name: "Members_details",
        meta: {
          title: "会员中心 / 会员详情",
          activeMenu: "/members/members_manage/members_list"
        }
      },
      {
        path: "/members/members_activity/members_activity_list",
        component: () =>
          import("@/views/members/members_activity/members_activity_list/index"),
        name: "Members_activity_list",
        meta: { title: "会员中心 / 会员活动" }
      },
      {

        path: "/members/members_activity/members_activity_new",
        component: () =>
          import("@/views/members/members_activity/members_activity_new/index"),
        name: "Members_activity_new",
        meta: {
          title: "会员中心 / 活动详情",
          activeMenu: "/members/members_activity/members_activity_list"
        }
      },
      {
        path: "/repast/commodity_manage/commodity_list",
        component: () =>
          import("@/views/repast/commodity_manage/commodity_list/index"),
        name: "Repast_Commodity_list",
        meta: { title: "餐饮团购 / 餐饮团购商品" }
      },
      {

        path: "/repast/commodity_manage/commodity_new",
        component: () =>
          import("@/views/repast/commodity_manage/commodity_new/index"),
        name: "Repast_Commodity_new",
        meta: {
          title: "线上百货 / 商品编辑",
          activeMenu: "/repast/commodity_manage/commodity_list"
        }
      },
      {
        path: "/repast/banner_config",
        name: "Repast_banner_config",
        component: () => import("@/views/repast/banner_config/index"),
        meta: { title: "餐饮团购banner配置" },
      },
      {
        path: "/online_department_store/commodity_manage/commodity_list",
        component: () =>
          import("@/views/online_department_store/commodity_manage/commodity_list/index"),
        name: "Online_Commodity_list",
        meta: { title: "线上百货 / 百货商品" }
      },
      {

        path: "/online_department_store/commodity_manage/commodity_new",
        component: () =>
          import("@/views/online_department_store/commodity_manage/commodity_new/index"),
        name: "Online_Commodity_new",
        meta: {
          title: "线上百货 / 商品编辑",
          activeMenu: "/online_department_store/commodity_manage/commodity_list"
        }
      },
      {
        path: "/online_department_store/categories_manage",
        name: "Department_categories_manage",
        component: () => import("@/views/online_department_store/categories_manage/index"),
        meta: { title: "线上百货 / 百货商品分类" },
      },
      {
        path: "/system/user",
        component: () => import("@/views/system/user"),
        name: "user",
        meta: { title: "系统管理 / 用户管理" }
      },
      {
        path: "/system/role",
        component: () => import("@/views/system/role"),
        name: "role",
        meta: { title: "系统管理 / 角色管理" }
      },
      {
        path: "/system/menu",
        component: () => import("@/views/system/menu"),
        name: "menu",
        meta: { title: "系统管理 / 菜单管理" }
      },
      {
        path: "/system/dept",
        component: () => import("@/views/system/dept"),
        name: "dept",
        meta: { title: "系统管理 / 部门管理" }
      },
      {
        path: "/live/list",
        component: () => import("@/views/live/list/index"),
        name: "Live_list",
        meta: { title: "直播间列表" }
      },
      { path: "*", redirect: "/404",meta: { title: "404" }},
    ]
  }
]
const createRouter = () =>
  new Router({
    // mode: 'history', // require service_center support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
