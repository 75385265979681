// import { login, logout, getInfo } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { resetRouter } from "@/router";
import request from "@/utils/request";

const getDefaultState = () => {
  return {
    token: getToken(),
    name: "",
    avatar: "",
    userId: "",
    roles: [],
    hasOrder: false,
    storeId: "",
    userObject:{}
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_USERID: (state, userId) => {
    state.userId = userId;
  },
  SET_STOREID: (state, storeId) => {
    state.storeId = storeId;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_ORDER: (state, status) => {
    state.hasOrder = status;
  },
  SET_USEROBJECT:(state,user)=>{
    state.userObject = user;
  }
};

const actions = {
  // 用户是否有订单
  order({ commit, state }) {
    return new Promise((resolve, reject) => {
      request({
        url: "http://www.tourask.com/mock/152/api/shopList",
        method: "get"
      })
        .then(res => {
          if (res.data.body.list.length > 0) {
            commit("SET_ORDER", true);
            resolve(state.hasOrder);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // user login
  login({ commit }, userInfo) {
    const { userId, password } = userInfo;
    return new Promise((resolve, reject) => {
      request({
        url: "/user-service/sysUser/login",
        method: "post",
        data: {
          userId: userId,
          password: password
        }
      })
        .then(response => {
          const { data } = response;
          //  console.log("loginresponse",response.data.body)
          commit("SET_TOKEN", data.body.token);
          commit("SET_USEROBJECT", data.body.user);
          setToken(data.body.token);
          commit("SET_STOREID", data.body.user.storeId);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      request({
        url: "/user-service/sysUser/logout",
        method: "post"
      })
        .then(response => {
          commit("SET_TOKEN", "");
          commit("SET_USEROBJECT", {});
          removeToken();
          commit("SET_STOREID",'');
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      // getInfo(state.token)
      request({
        // baseURL: "https://wechat.lirimall.com:9443",
        url: "/user-service/sysUser/token/" + state.token,
        method: "get"
      })
        .then(response => {
          const { data } = response;
          if (!data) {
            reject("Verification failed, please Login again.");
          }
          const { name, avatarMediaid, id, userRoleVos, storeVos } = data.body;
          let storeId = "";
          if (storeVos && storeVos.length > 0) {
            storeId = storeVos[0].id;
          }
          const roles = [];
          userRoleVos.forEach(e => {
            roles.push(e.roleId);
          });
          if (!roles || roles.length <= 0) {
            reject("getInfo: roles must be a non-null array!");
          }
          // console.log("Mei",roles)
          commit("SET_ROLES", roles);
          commit("SET_NAME", name);
          commit("SET_AVATAR", avatarMediaid);
          commit("SET_USERID", id);
          commit("SET_STOREID", storeId);
          resolve(data.body);
        })
        .catch(error => {
          reject(error);
        });
    });
  },



  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      commit("SET_ROLES", []);
      removeToken();
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
